import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpService } from './http.service';
import { Reservation, Exam, Reservations, PaginatedResponse, Certification, Contact } from '@models/faraday.model';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class FaradayService {
  api = environment.API_URL;
  promiseResult: any;
  urls = {
    faraday: {
      platforms: `${this.api}/platforms`,
      reservation: `${this.api}/reservation`,
      reservations: `${this.api}/reservations`,
      exam: `${this.api}/exam`,
      certification: `${this.api}/certification`,
      checkReservation: `${this.api}/check_reservation`,
      contact: `${this.api}/contact`,
      contacts: `${this.api}/contacts`
    }
  };

  constructor(private httpService: HttpService) {}

  // removed getLatestReservationByExamId because it's just a duplicate of this function
  // it's also incorrect because it doesn't include the ldap_username
  getReservationsByExamId(id: string): Observable<PaginatedResponse> {
    const token = sessionStorage.getItem('idToken');
    const userProfile = jwt_decode(token);
    const params = {
      headers: this.getHeaders(),
      queryParams: [
        { name: 'exam', value: id },
        { name: 'orderBy', value: 'creation_date' },
        { name: 'sortDir', value: 'desc' },
        { name: 'ldap_username', value: userProfile.nickname }
      ]
    };

    return this.httpService.get<Reservations[]>(this.urls.faraday.reservations, params);
  }

  getReservationById(id: string): Observable<Reservation> {
    const params = {
      headers: this.getHeaders()
    };
    const reservationURL = `${this.urls.faraday.reservation}/${id}`;
    return this.httpService.get<Reservation>(reservationURL, params);
  }

  getExamById(id: string): Observable<Exam> {
    const params = {
      headers: this.getHeaders()
    };

    return this.httpService.get<Exam>(`${this.urls.faraday.exam}/${id}`, params);
  }

  getCertificationById(id: string): Observable<Certification> {
    const params = {
      headers: this.getHeaders()
    };
    const certificationUrl = `${this.urls.faraday.certification}/${id}`;
    return this.httpService.get<Certification>(certificationUrl, params);
  }

  getContactInformation(): Observable<Contact> {
    const params = {
      headers: this.getHeaders()
    };

    return this.httpService.get<Contact>(`${this.urls.faraday.contacts}`, params);
  }

  getContactByEmail(email: string): Observable<Contact[]> {
    const params = {
      headers: this.getHeaders(),
      queryParams: [{ name: 'email', value: email }]
    };

    return this.httpService.get<Contact[]>(`${this.urls.faraday.contacts}`, params);
  }

  updateReservation(id: string, data: {}) {
    const params = {
      headers: this.getHeaders(),
      body: data
    };

    return this.httpService.patch(`${this.urls.faraday.reservation}/${id}`, params);
  }

  updateContact(id: string, data: {}) {
    const params = {
      headers: this.getHeaders(),
      body: data
    };

    return this.httpService.patch(`${this.urls.faraday.contact}/${id}`, params);
  }

  claimRetake(reservation: Reservation) {
    const params = {
      headers: this.getHeaders()
    };

    return this.httpService.patch(`${this.urls.faraday.reservation}/${reservation.partner_resid}/claim_retake`, params);
  }

  private getHeaders() {
    const headers = new HttpHeaders(`Authorization: Bearer ${sessionStorage.getItem('idToken')}`);
    headers.set('Content-Type', 'application/json; charset=utf-8');

    return headers;
  }
}
